<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10561_361512)">
      <path
        d="M18.7109 8.71094L12.2109 15.2109L5.71094 8.71094"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_10561_361512">
        <rect
          width="14.41"
          height="8.62"
          fill="white"
          transform="translate(5 8)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
